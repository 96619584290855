<template>
    <div>
        <card-header title="Participant Flights" icon="fa-plane"/>

        <card-body>
            <card-list>
                <container-list-item padded class="pt-3 pb-3">
                    <b-field>
                        <b-select v-model="filter" icon="filter" expanded>
                            <option value="all">All Flights</option>
                            <option value="arrive">By Arrival Airport</option>
                            <option value="depart">By Departure Airport</option>
                        </b-select>
                    </b-field>
                    <b-field>
                        <b-select v-model="airport" icon="plane" expanded v-if="filter==='arrive'">
                            <option :value="a.id" v-for="a in arriveAirports">{{a.title}}</option>
                        </b-select>
                        <b-select v-model="airport" icon="plane" expanded v-if="filter==='depart'">
                            <option :value="a.id" v-for="a in arriveAirports">{{a.title}}</option>
                        </b-select>
                    </b-field>
                </container-list-item>
                <subheader-list-item title="Flights" icon="fas fa-plane"/>

                <template v-for="g in fliteredFlights">

                    <registration-list-item
                        :card="card"
                        :registration="g.registration"
                        hide-trip
                        @click="$openCard('registration', {registrationId: g.registration.id}, card)"
                        :active="child && child.definition.component === 'registration' && child.props.registrationId === g.registration.id.toString()"
                    />
                    <separator-list-item/>
                    <flight-list-item
                        :card="card"
                        :flight="flight"
                        v-for="flight in g.flights"
                        v-if="filter === 'all' || (filter === 'arrive' && flight.arrive_airport.id === airport) || (filter === 'depart' && flight.depart_airport.id === airport)"
                        :show-dates="(filter !== 'all')"
                        :active="child && child.definition.component === 'edit-flight' && child.props.flightId === flight.id.toString()"
                        @click="$openCard('edit-flight', {registrationId: flight.registration_id, flightId: flight.id}, card)"
                    />
                    <separator-list-item/>
                    <separator-list-item/>
                </template>
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import {client as http} from '../../http_client';

    import CardHeader from '@/TIER/components/CardHeader';
    import CardToolbar from '@/TIER/components/CardToolbar';
    import CardBody from '@/TIER/components/CardBody';
    import CardList from '@/TIER/components/CardList';
    import FlightListItem from '../../components/FlightListItem';
    import RegistrationListItem from "@/components/RegistrationListItem";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import SeparatorListItem from "@/TIER/components/SeparatorListItem";
    import TripListItem from "../../components/TripListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    export default {
        props: ['card', 'child', 'props'],
        components: {ContainerListItem, TripListItem, SeparatorListItem, SubheaderListItem, RegistrationListItem, FlightListItem, CardBody, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                arriveAirports: [],
                departAirports: [],
                flights: [],
                flightGroups: [],
                filter: 'all',
                airport: null
            };
        },
        computed: {
            fliteredFlights: function() {
                if (this.filter === 'all') {
                    return this.flightGroups;
                } else {
                    let result = [];
                    for (let i = 0; i < this.flightGroups.length; i++) {
                        const flightGroup = this.flightGroups[i];

                        for (let j = 0; j < flightGroup.flights.length; j++) {
                            const flight = flightGroup.flights[j];
                            if (this.filter === 'arrive' && flight.arrive_airport.id === this.airport) {
                                result.push(flightGroup);
                                break;
                            }
                            if (this.filter === 'depart' && flight.depart_airport.id === this.airport) {
                                result.push(flightGroup);
                                break;
                            }
                        }
                    }
                    return result;
                }
            }
        },
        methods: {
            loadFlights: function(force) {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId + '/flights', {force}).then(response => {
                    this.flights = response.data;

                    let arriveAirports = {};
                    let departAirports = {}
                    for (let i = 0; i < this.flights.length; i++) {
                        const flight = this.flights[i];
                        arriveAirports[flight.arrive_airport.id] = flight.arrive_airport.iata + ' - ' + flight.arrive_airport.name + ', ' + flight.arrive_airport.city + ', ' + flight.arrive_airport.country;
                        departAirports[flight.depart_airport.id] = flight.depart_airport.iata + ' - ' + flight.depart_airport.name + ', ' + flight.depart_airport.city + ', ' + flight.depart_airport.country;
                    }

                    for (const id in arriveAirports) {
                        this.arriveAirports.push({id: parseInt(id), title: arriveAirports[id]});
                    }
                    for (const id in departAirports) {
                        this.departAirports.push({id: parseInt(id), title: departAirports[id]});
                    }

                    this.arriveAirports.sort((a, b) => (a.title > b.title) ? 1 : -1)
                    this.departAirports.sort((a, b) => (a.title > b.title) ? 1 : -1)

                    let flightGroups = {};
                    for (let i = 0; i < this.flights.length; i++) {
                        const f = this.flights[i];
                        if (!flightGroups[f.registration_id]) flightGroups[f.registration_id] = [];
                        flightGroups[f.registration_id].push(f);
                    }

                    for (const [key, value] of Object.entries(flightGroups)) {
                        value.sort(function(a, b) {
                            return a.depart_at - b.depart_at;
                        });
                        value.reverse();
                        this.flightGroups.push({
                            registration: value[0].registration,
                            flights: value
                        })
                    }

                    // console.log(this.flightGroups);

                    this.flightGroups.sort((a, b) => (a.registration.user.last_name > b.registration.user.last_name) ? 1 : -1)


                    this.$emit('loading', false);
                });
            },
            reload: function() {
                this.loadFlights(true);
            }
        },
        mounted() {
            this.loadFlights();
        }
    };
</script>
